
/**media query for tablet**/
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .not-allowed-signature {
        height: 100%;
        width: 100%;
        background: #f0f2f5;
        margin-bottom: 30px;
        min-height: 180px;
    }
}
.moe-bg {
    position: relative;
}

.moe-bg:before {
    background-image: url("/src/assets/img/moe-background.jpg");
    opacity: 0.5;
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}

img {
    max-width: 100px;
}
.table-action {
    cursor: pointer;
}
.table-action svg {
    color: #6c757d;
}

.table-action svg:hover {
    color: #212529;
}

.custom-tab.tab-vertical .nav-tabs {
    margin-right: 10px;
    background: white;
}

.custom-tab.tab-vertical .nav-tabs .nav-link.active {
    background: #3f80ea;
    color: white;
}

.custom-tab.tab-vertical .nav-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.custom-tab.tab-vertical .nav-link:hover:not(.active) {
    color: #495057;
    text-decoration: none;
    background-color: #f4f7f9;
}

.fault-image-holder {
    margin: 8px;
    border: 1px solid #ddd;
    box-shadow: 1px 1px 5px 0 #a2958a;
    padding: 6px;
    float: left;
    display: inline-block;
    width: 140px;
    overflow: hidden;
    position: relative;
}

.fault-image-holder img {
    max-width: none;
    width: 100%;
    height: -webkit-fill-available;
}

.fault-image-holder small {
    font-size: 70%;
    display: block;
    text-align: center;
    margin-top: 10px;
}

.fault-image-holder .close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: 900;
}
.file-uploader-modal img {
    max-width: none;
    width: 100%;
}

.table-layout-fixed {
    table-layout: fixed;
}

.cursor-not-allowed {
    cursor: not-allowed;
    pointer-events: none;
}

.e-file-upload-btn {
    display: none !important;
}
.circle {
    position: relative;
}
.circle::before {
    content: "";
    width: 13px;
    height: 13px;
    border-radius: 100%;
    display: block;
    background: #ccc;
    position: absolute;
    left: -20px;
    top: 3px;
}

.circle.background-orange::before {
    background: #f0ad4e;
}

.circle.background-green::before {
    background: #4bbf73;
}

.circle.background-blue::before {
    background: #5bc0de;
}

.tab .nav-tabs .nav-link.active {
    color: #568fed;
}

.maintenance-schedule .tab .card {
    border-left: 3px solid transparent;
}

.maintenance-schedule .tab .card-body {
    position: relative;
}

.maintenance-schedule .tab .card-body.turf {
    border-left: 3px solid #e3d134;
}

.maintenance-schedule .tab .card-body.building {
    border-left: 3px solid #4bbf73;
}

.maintenance-schedule .tab .card-body .date {
    position: absolute;
    background: aliceblue;
    padding: 5px 15px;
    text-align: center;
    font-weight: bold;
    right: 0;
    top: 0;
    font-size: 12px;
    line-height: 1.3;
    color: #4c94d2;
}

.quotation-items > .tooltip-inner {
    background-color: #e7f1ff;
    color: black;
    padding: 10px;
    max-width: 320px !important;
}

.quotation-items > .tooltip.bs-tooltip-top,
.quotation-items > .tooltip-arrow::before {
    border-top-color: #e7f1ff;
}

.quotation-items > .tooltip.bs-tooltip-bottom,
.quotation-items > .tooltip-arrow::before {
    border-bottom-color: #e7f1ff;
}

.quotation-items > .tooltip.bs-tooltip-start,
.quotation-items > .tooltip-arrow::before {
    border-left-color: #e7f1ff;
}

.quotation-items > .tooltip.bs-tooltip-end,
.quotation-items > .tooltip-arrow::before {
    border-right-color: #e7f1ff;
}

.header {
    background-color: #f4f7f9 !important;
}

.border-grey {
    border: 1px solid rgba(0, 0, 0, 0.175);
}

.e-control-wrapper.is-invalid {
    border-color: #d9534f !important;
}

.count-badge {
    width: 23px;
    height: 23px;
    background-color: #df190c;
    display: block;
    border-radius: 100%;
    position: absolute;
    right: -10px;
    color: white;
    font-size: 11px;
    text-align: center;
    line-height: 23px;
    z-index: 9;
}

.count-items {
    width: 23px;
    height: 23px;
    background-color: #df190c;
    display: block;
    border-radius: 100%;
    position: absolute;
    color: white;
    font-size: 11px;
    text-align: center;
    line-height: 23px;
    z-index: 9;
    right: -5px;
    top: -21px;
}

.border-dashed {
    border: 1px dashed #adb5bd;
    padding: 10px;
}

.e-input[disabled] {
    background-color: #e2e8ee !important;
}
.not-allowed-signature {
    height: 190px;
    width: 100%;
    background: #f0f2f5;
    min-height: 186px;
}

.preview-signature {
    height: 190px;
    width: 100%;
    max-width: initial;
}

.preview-image {
    width: 160px;
    max-width: initial;
}

.preview-modal {
    width: 100%;
    max-width: initial;
}

.upload-signature {
    height: 215px;
}

.eye-icon {
    height: 20px;
    width: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

._loading_overlay_content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.currency:before,
td[data-colindex="5"]:before {
    content: "S$";
    float: left;
    padding-left: 5px;
}

.react-select__indicator-separator,
.css-1okebmr-indicatorSeparator {
    opacity: 0;
}

.css-tlfecz-indicatorContainer {
    color: #000 !important;
}

.search-box {
    padding: 0.45rem 0.7rem;
}

.form-box {
    padding: 0.45rem 0.7rem;
}

.search-syncfusion:not(.e-datepicker) {
    padding: 0.2rem 0rem;
}

.e-pager .e-numericitem,
.e-pager div.e-icons.e-pager-default,
.e-pager:hover,
.e-pager .e-currentitem:hover,
.e-pager .e-currentitem {
    color: black !important;
}

.e-pager .e-currentitem,
.e-pager .e-currentitem:hover,
.e-pager .e-currentitem {
    border-right-color: #e9ecef;
    border: 3px solid #e9ecef;
}

.e-pager .e-numericitem.e-currentitem.e-focused,
.e-grid .e-focused:not(.e-menu-item) {
    box-shadow: none;
}

.css-df17o1 {
    z-index: 10000 !important;
}

.signature-container {
    margin-right: 0;
    margin-left: 0;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
}

.new-line {
    white-space: pre-line;
}

/*********service report***************/
#service-report-template .element {
    font-size: 16px;
}

#service-report-template .sub-element {
    font-size: 14px;
}

#service-report-template .sub-items {
    font-size: 12px;
}

.signature-image img {
    width: auto;
    max-width: 50%;
    height: 100px !important;
}

.table-responsive:has(.tableClone) {
    height: 15px;
}

.table-responsive:has(.ma-user-org-table) {
    overflow-x: unset !important;
}
